import {Injectable} from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from './config.service';

@Injectable()
export class EmailSignService {
    constructor(private http: HttpClient) {
        console.log('Email Sign service initialized...');
    }

    getUserDetails(userId: string) {
        return this.http.get(Config.ApiBaseUrl + Config.GetUsersApiUrl + userId).pipe(
          map((res: any) => res.json())
        );
    }

    postUserDetails(data: any) {
        const postUrl: string = Config.ApiBaseUrl + Config.PostUsersApiUrl;
        const postData: string = JSON.stringify(data);
        console.log(postData);
        const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept':'application/json' });
        return this.http.post(postUrl, postData, { headers } ).pipe(
          map((res: any) => res)
        );
    }

    postUserImageFile(userId: number, imageType: string, file: File): any {
        const formData: FormData = new FormData();
        formData.append('userId', userId.toString());
        formData.append('imageType', imageType.toString());
        formData.append('uploadFile', file, file.name);
        const postUrl: string = Config.ApiBaseUrl + '/api/Users/UploadImage';
        return this.http.post(postUrl, formData).pipe(map(res => res));
    }

}
