import { Component, AfterViewInit } from '@angular/core';
import { EmailSignService } from '../services/emailsign.service';
import { Config } from '../services/config.service';

declare var $: any;

@Component({
  selector: 'email-sign',
  templateUrl: './emailsign.component.html',
  providers: [EmailSignService]
})

export class EmailSignComponent implements AfterViewInit {
  user: User = new User();

  constructor(private emailSignService: EmailSignService) {
    // this.emailSignService.getUserDetails('12').subscribe(user => {
    //     this.user = user;
    //     this.SetImageUrl();
    //     console.log(this.user);
    // });
  }

  onFontChange(selectedFont: string) {
    console.log('selectedFont = ' + selectedFont);
    this.user.FontFamily = selectedFont;
  }

  onFontSizeChange(selectedFontSize: string) {
    console.log('selectedFontSize = ' + selectedFontSize);
    this.user.FontSize = selectedFontSize;
  }

  ngAfterViewInit() {
    $('#userForm').validate({
      rules: {
        txtFirstName: {
          minlength: 2,
          required: true
        },
        txtEmail: {
          required: true,
          email: true
        },
        txtLastName: {
          minlength: 2,
          required: true
        },
        txtPhoneNumber: {
          required: true
        },
        txtMobileNumber: {
          required: true
        }
      },
      highlight: (element) => {
        $('div.form-error').show();
        $(element).removeClass('valid').removeClass('success').addClass('error');
      },
      success: (element) => {
        $('div.form-error').hide();
        $(element).prev().addClass('valid').removeClass('error').addClass('success');
      }
    });

    $('#codeTab').on('click', function (e) {
      $('#code pre').text($('#myCarousel .carousel-inner .item.active').html());
    });
  }

  isFormValid() {
    return $('#userForm').valid();
  }

  onLogoUpload(event: any) {
    if (this.isFormValid()) {
      this.emailSignService.postUserDetails(this.user).subscribe(user => {
        // console.log(user);
        this.user = user;
      }, err => {}, () => {
        this.UploadImage(event, 'logo');
      });
    }
  }

  onBannerUpload(event: any) {
    if (this.isFormValid()) {
      this.emailSignService.postUserDetails(this.user).subscribe(user => {
        // console.log(user);
        this.user = user;
      }, err => {}, () => {
        this.UploadImage(event, 'banner');
      });
    }
  }

  private UploadImage(event: any, imageType: string) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      // console.log('Uploading image!');
      this.emailSignService.postUserImageFile(this.user.UserId, imageType, file).subscribe((res: any) => {
        let imageUrl = Config.ImageContainerVirtualUrl + this.user.UserId + '/' + file.name;
        if (imageType.toLowerCase() === 'logo') {
          this.user.LogoImageUrl = imageUrl;
        } else {
          this.user.BannerImageUrl = imageUrl;
        }
        this.SetImageUrl();
      }, err => { 
		alert('Could not upload the image file. Try again later or enter image url.');
	  });
    }
  }

  onSubmit() {
    if (this.isFormValid()) {
      this.emailSignService.postUserDetails(this.user).subscribe(user => {
        this.user = user;
        this.SetImageUrl();
      });
    }
  }

  onCancel() {
    this.user = new User();
  }

  SetImageUrl() {
    if (this.user.LogoImageUrl && this.user.LogoImageUrl.indexOf('http') < 0) {
      this.user.LogoImageFullUrl = Config.ApiBaseUrl + this.user.LogoImageUrl;
    } else {
      this.user.LogoImageFullUrl = this.user.LogoImageUrl;
    }
    if (this.user.BannerImageUrl && this.user.BannerImageUrl.indexOf('http') < 0) {
      this.user.BannerImageFullUrl = Config.ApiBaseUrl + this.user.BannerImageUrl;
    } else {
      this.user.BannerImageFullUrl = this.user.BannerImageUrl;
    }
  }
}

export class User {
  UserId: number;
  FirstName: string = 'First name';
  LastName: string = 'Last name';
  Email: string = 'yourmail@yourcompany.com';
  JobTitle: string = 'Job Title';
  CompanyName: string = 'Company name';
  Website: string = 'www.yourcompany.com';
  PhoneNumber: string = '07712345678';
  MobileNumber: string = '01234567890';
  LogoImageUrl: string;
  BannerImageUrl: string;
  BannerLinkUrl: string;
  Disclaimer: string = 'The company accepts no liability for the content of this email, or for the consequences of any actions taken on the basis of the information provided, unless that information is subsequently confirmed in writing. If you are not the intended recipient you are notified that disclosing, copying, distributing or taking any action in reliance on the contents of this information is strictly prohibited.';
  FontFamily: string = 'Times New Roman';
  FontSize: string = '12';
  FacebookUrl: string = 'www.facebook.com';
  TwitterUrl: string = 'www.twitter.com';
  YoutubeUrl: string = 'www.youtube.com';
  GooglePlusUrl: string = 'plus.google.com';
  LinkedInUrl: string;
  PinterestUrl: string;
  SperseUrl: string;
  OtherSocialUrl: string;
  // These below fields are not to be posted to server.
  LogoImageFullUrl: string;   // = '/images/yourlogo.jpg';
  BannerImageFullUrl: string = 'assets/images/YourBanner.jpg';
}
